// Here you can add other styles
.header.header-sticky.mb-4 {
  background: #003473!important;
  color: #ffffff!important;
}
button.header-toggler.ps-1 {
  color: #ffffff;
}
a.nav-link {
  color: #ffffff !important;
}
li.breadcrumb-item.active {
  color: #f39c12!important;
}
li.breadcrumb-item a {
  color: #ffffff;
}
.simplebar-content {
  background: #003473;
}
.sidebar-brand.d-none.d-md-flex {
  background: #003473 !important;
}
.card.text-white.bg-primary.py-5 {
  background: #003473 !important;
}
.btn:hover {
  background:#f39c12;
  border:#f39c12;
}
.btn{
  background: #003473 !important;
  border: #003473 !important;
}
.sort-link {
  cursor: pointer;
}
iframe {
  display: none;
}
.layout-sidebar-compact .sidebar-left .navigation-left .nav-item.active {
  color: #feef00 !important;
  border-left: 8px solid #ffc107 !important;
}
.layout-sidebar-compact
  .sidebar-left
  .navigation-left
  .nav-item.active
  .nav-item-hold
  i {
  color: #feef00 !important;
}

.landing-menu {
  width: auto;
  overflow-x: hidden;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}
img.logo {
  height: 30px;
}
.large-menu {
  padding-top: 10px;
}
.large-menu a {
  font-size: 14px !important;
  color: #000000 !important;
}
.large-menu a:hover {
  color: #003473;
}
.menutxt {
  color: #000000;
  font-weight: 700;
  display: block;
  font-size: 80%;
}

.menutxt:hover {
  color: #000000;
  font-size: 100%;
}
.landing-menu ul li a:hover > span {
  color: #000000;
  font-size: 100%;
}
.countdownbox {
  background: #003473;
  color: #ffffff !important;
  padding: 10px;
  border-radius: 16px;
}

.countdownbox h4,
.countdownbox h5 {
  color: #ffffff !important;
}
.cursor-pointer.d-flex.justify-content-between.pr-3.card-header {
  font-size: 1.1rem;
}

/*UPDATE*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

body {
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
.card-title,
.text-title {
  color: #00214e !important;
}
.layout-sidebar-compact .sidebar-left .navigation-left .nav-item.active {
  border-left: 5px solid #ffc107 !important;
}
.layout-sidebar-compact .sidebar-left-secondary {
  background: #00214e !important;
}
.layout-sidebar-compact .sidebar-left-secondary .logo {
  margin-top: 20px !important;
  margin-bottom: 60px;
}
.sidebar-left-secondary .header {
  margin-left: 20px !important;
}
.sidebar-left-secondary .header h5 {
  font-family: "Poppins", sans-serif;
  color: #fff !important;
  font-size: 16px;
  text-transform: uppercase;
}
.sidebar-left-secondary ul .nav-item {
  padding-left: 12px;
}
.sidebar-left-secondary ul .nav-item a {
  padding: 12px 20px !important;
  color: #fff !important;
  font-family: "Poppins", sans-serif;
}
.sidebar-left-secondary ul .nav-item.selected a {
  background-color: #f0f5f9;
  color: #00214e !important;
  border-top-left-radius: 99px;
  border-bottom-left-radius: 99px;
  font-size: 14px !important;
}
.layout-sidebar-compact .sidebar-left-secondary,
.layout-sidebar-compact .sidebar-left {
  box-shadow: none !important;
}
.layout-sidebar-compact .main-content-wrap {
  background: #f0f5f9 !important;
  padding-left: 15px !important;
  padding-bottom: 40px !important;
}
.layout-sidebar-compact .main-header {
  background: #fff !important;
}
.heading-block h4 {
  font-size: 16px;
  color: #00214e;
  margin-bottom: 0;
}
.dashboard-select-year select {
  height: 48px;
  border: solid 1px #ddd;
  border-radius: 99px;
  min-width: 272px;
  padding: 0 20px !important;
}
.dashboard-select-year {
  margin-left: 30px;
}
.dashboard-top-heading {
  border-bottom: solid 1px rgb(0 33 78 / 50%);
  padding-bottom: 5px;
  margin-bottom: 10px;
}
.card {
  border-radius: 15px !important;
  -webkit-box-shadow: 0px -10px 20px 0px rgba(0, 33, 78, 0.15) !important;
  -moz-box-shadow: 0px -10px 20px 0px rgba(0, 33, 78, 0.15) !important;
  box-shadow: 0px -10px 20px 0px rgba(0, 33, 78, 0.15) !important;
  margin-bottom: 30px !important;
}
.card-body {
  padding: 35px !important;
}
.simple-card-title h3 {
  font-size: 16px;
  font-weight: 700;
}
.donut-widget-wrap h6 {
  font-size: 0.8rem;
  margin: 0;
}
.donut-widget-wrap h5 {
  font-size: 16px;
}
.donut-widget-wrap h4 {
  font-size: 16px !important;
  font-weight: 400;
}
.row.small-gutter {
  margin-left: -10px;
  margin-right: -10px;
}
.row.small-gutter > [class*="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}
.card-body > h4 {
  font-size: 16px;
  font-weight: 700;
}
.ul-widget1 .ul-widget2__info {
  font-size: 16px;
  font-weight: 700;
  color: #00214e;
}
.ul-widget1 .ul-widget2__info .ul-widget2__username {
  font-size: 14px;
  font-weight: 400;
  color: #555555;
  text-transform: capitalize;
}
.ul-widget1 > .ul-widget4__item:nth-of-type(even) {
  background-color: #f7f8fb;
}
.ul-widget1 > .ul-widget4__item {
  border-bottom: none;
  padding: 10px 20px;
}
.ul-widget1 > .ul-widget4__item .ul-widget2__info {
  margin-left: 0;
}
.ul-widget__body .ul-widget1 {
  margin-left: -20px;
  width: calc(100% + 40px);
}
.ul-widget1 > .ul-widget4__item .ul-widget4__number {
  font-size: 20px;
  color: #00214e;
}
.card-panel .card-header {
  background-color: #00214e;
  color: #fff;
  font-size: 16px !important;
  font-weight: 700;
  padding: 20px 35px;
}
.app-footer {
  display: none;
}
.table thead th {
  border: none;
  background-color: #f7f8fb;
  font-size: 16px;
  color: #00214e;
}
.table th,
.table td {
  border-top: none;
  font-size: 14px;
  color: #555555;
}
.table tbody th {
  font-weight: 400;
  color: #555;
}
.card-body {
  padding: 30px 20px !important;
}
.card-panel .card-body {
  padding: 25px 20px !important;
}

.dahsboard-card .card-body {
  padding: 30px 15px !important;
}

.layout-sidebar-compact .sidebar-left-secondary .childNav li.nav-item a:hover,
.layout-sidebar-compact
  .sidebar-left-secondary
  .childNav
  li.nav-item
  > div:hover {
  background: transparent !important;
  color: #ffd200 !important;
}
select::-ms-expand {
  display: none;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
}
/*select {
    background-image: url(arrow-select.svg);
    background-repeat: no-repeat;
    background-position: 93% 52%;
}*/
.accordion .dropdown-toggle {
  display: none;
}
.accordion > .card > .card-header {
  background-image: url(arrow-accordion.svg);
  background-repeat: no-repeat;
  background-position: 97% 54%;
  background-size: 16px;
}
.detil-realisasi {
  display: flex;
}
.detil-realisasi h5 {
  font-size: 0.8rem;
}
.belanja {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 4px;
}
.detil-realisasi .item {
  margin-right: 30px;
}
.detil-realisasi .item.last {
  margin-right: 0px;
}
.detil-realisasi .item span {
  font-size: 0.9rem;
}
.toptable {
  display: flex;
  flex-direction: row;
}
.toptable .bulan {
  flex-grow: 2;
}
.toptable .search {
  justify-content: flex-end;
  margin-top: -30px;
}
.red-text {
  color: #cc0000 !important;
  font-weight: 700;
}
.document-form{
  background: #e5e5e5;
  padding: 10px;
  border-radius: 10px;
}
span.ul-widget2__username.belanja {
  font-weight: 700 !important;
}
.layout-sidebar-compact .sidebar-left-secondary .sidebar-close{
  display:block;
  color:white;
}

.widget-box {
  background-color: #cccccc;
  padding: 10px;
  border-radius: 10px;
}
.widget-box h4{
  font-size: 100%;
  font-weight: bold;
  text-align: center;
}
.widget-box .widget-box-body{
  display: flex;
  justify-content: center;
}
.widget-box .widget-box-body .widget-box-text{
     flex-grow: 2;
    text-align: center;
    font-size: 200%;
    font-weight: 700;
    padding-left: 25%;
}
.widget-box .widget-box-body .widget-box-icon{
    flex-grow: 1;
    text-align: center;
    font-size: 200%;
    font-weight: 700;
}
.ticket-toolbar{
  display: flex;
}
.ticket-toolbar .search{
  flex-grow: 20;
}
.ticket-toolbar .action{
  flex-grow: 1;
}
.ticket-toolbar .action.filter i{
  font-size: 200%;
}
.ticket-status {
    padding: 10px;
    background-color: #cccccc;
    border-radius: 10px;
    font-size: 80%;
    display: flex;
    justify-content: center;
}
.ticket-status .text{
  flex-grow: 2;
}
.ticket-status .icon{
  flex-grow:  1;
  text-align: right;

}
.ticket-status .icon i{
  font-size: 200%;

}
.ticket-status.pending{
  background-color: #ffeaa7;
}
.ticket-status.approved{
  background-color: #55efc4;
}
.ticket-status.rejected{
  background-color: #ff7675;
}
.ml-2{
  margin-left:4px;
}
.ml-4{
  margin-left:8px;
}
.ml-6{
  margin-left:12px;
}
.ml-8{
  margin-left:16px;
}
.ml-10{
  margin-left:20px;
}
.ml-12{
  margin-left:24px;
}
.mt-2{
  margin-left:2px;
}
.mt-4{
  margin-top:8px;
}
.mt-6{
  margin-top:12px;
}
.mt-8{
  margin-top:16px;
}
.mt-10{
  margin-top:20px;
}
.mt-12{
  margin-top:24px;
}
.mr-2{
  margin-right:4px;
}
.mr-4{
  margin-right:8px;
}
.mr-6{
  margin-right:12px;
}
.mr-8{
  margin-right:16px;
}
.mr-10{
  margin-right:20px;
}
.mr-12{
  margin-right:24px;
}
@media (max-width: 1024px) {

}
@media (max-width: 768px) {

}
@media (max-width: 480px) {
    .dashboard-top-heading.d-flex.align-items-center{
        display:block !important;
    }
    .main-content-wrap.d-flex.flex-column {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .dashboard-select-year {
        margin-left: 0px;
    }
    .card {
        height:auto !important;
    }
    .donut-widget-wrap {
        flex-direction: column !important;
    }
    .ul-widget4__item.ul-widget4__users {
      /*  display:block !important;*/
    }
    .ul-widget1{
        width:100% !important;
    }
}
